




























import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default

	components: {
		SsrCarousel
	}

	props: block: Object

	data: -> config:
		slidesPerPage: 1
		showArrows: false
		showDots: true
		paginateBySlide: true

	computed:
		firstAsset: -> @block.children[0].image[0]
		secondAsset: -> @block.children[1].image[0]


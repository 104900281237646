import { render, staticRenderFns } from "./mobile-story-scroll.vue?vue&type=template&id=98e9c2f6&scoped=true&lang=pug&"
import script from "./mobile-story-scroll.vue?vue&type=script&lang=coffee&"
export * from "./mobile-story-scroll.vue?vue&type=script&lang=coffee&"
import style0 from "./mobile-story-scroll.vue?vue&type=style&index=0&id=98e9c2f6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e9c2f6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})














import DesktopStoryScroll from './desktop-story-scroll'
import MobileStoryScroll from './mobile-story-scroll'
export default

	components: {
		DesktopStoryScroll
		MobileStoryScroll
	}

	props: block: Object

